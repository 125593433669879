import Link from 'next/link';

import Button from '../Button';

import classes from './index.module.less';

const SectionContactUs = () => {
	return (
		<div className={classes.section}>
			<div className="container animateSlideUp">
				<h2>
					Our approach combines creative consulting with technical expertise to redefine what’s
					possible to deliver results.
				</h2>
				<Link href="/contact">
					<Button text="Contact Us" />
				</Link>
			</div>
		</div>
	);
};

export default SectionContactUs;
