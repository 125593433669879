import { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import Image from '../Image';

import classes from './index.module.less';

interface Props {
	show?: boolean;
	setShow?: (val: boolean) => void;
}

const Sidebar = ({ show = false, setShow }: Props) => {
	const router = useRouter();

	const [firstLoad, setFirstLoad] = useState<boolean>(true);

	const handleCloseSidebar = () => {
		setShow?.(false);
	};

	useEffect(() => {
		if (show) {
			setFirstLoad(false);
		}
	}, [show]);

	useEffect(() => {
		if (show) {
			handleCloseSidebar();
		}
	}, [router.pathname]);

	return (
		<>
			{show && <div className={classNames(classes['sidebar-mask'])} onClick={handleCloseSidebar} />}
			<div
				className={classNames(classes.sidebar, {
					[classes.show]: show,
					[classes.hide]: !firstLoad && !show,
				})}
			>
				<Image
					className={classes['close-btn']}
					alt="icon close"
					src="/icons/icon-close.svg"
					width={42}
					height={42}
					onClick={handleCloseSidebar}
				/>
				<h6>
					<Link href="/" onClick={handleCloseSidebar}>
						Home
					</Link>
				</h6>
				<h6>
					<Link href="/contact" onClick={handleCloseSidebar}>
						About
					</Link>
				</h6>
				<h6>
					<Link href="/projects" onClick={handleCloseSidebar}>
						Project
					</Link>
				</h6>
				<h6>
					<Link href="/contact" onClick={handleCloseSidebar}>
						Contact
					</Link>
				</h6>
			</div>
		</>
	);
};

export default Sidebar;
