import Link from 'next/link';
import classNames from 'classnames';

import Image from 'src/components/Image';

import classes from './index.module.less';

const Footer = () => {
	return (
		<footer>
			<div className={classes['footer-container']}>
				<div className="container pb-80 pt-80 ontop">
					<div className="row">
						<div className={classNames(classes['company-info'], 'col-md-6 d-flex align-items-end')}>
							<div className="w-fit-content d-flex flex-column align-items-center">
								<Image alt="Tekuton" src="/images/logo-hor.svg" width={107} height={75} />
								<span className="mt-3">PT Tekuton Group Indonesia © 2024 </span>
							</div>
						</div>
						<div className="col-md-6">
							<div className="row" style={{ rowGap: 0 }}>
								<div className={classNames('col-xl-4 col-md-6', classes.menu)}>
									<h6>
										<Link href="/">Home</Link>
									</h6>
									<h6>
										<Link href="/contact">About Us</Link>
									</h6>
									<h6>
										<Link href="/projects">Portfolio</Link>
									</h6>
									<h6>
										<Link href="/contact">Contact Us</Link>
									</h6>
								</div>
								<div className={classNames('col-xl-4 col-md-6', classes.menu)}>
									<h6>
										<Link href="#">Privacy Policy</Link>
									</h6>
									<h6>
										<Link href="#">Term of Use</Link>
									</h6>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
