import { useEffect } from 'react';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';

import MainLayout from 'src/components/MainLayout';

import '../styles/index.less';

type AppPropsWithLayout = AppProps & {
	Component: React.ReactNode;
};

const AppRoot = (props: AppPropsWithLayout) => {
	const { Component, pageProps }: any = props;

	const router = useRouter();

	function isInViewport(element: any) {
		const rect = element.getBoundingClientRect();
		const viewport = window.innerHeight || document.documentElement.clientHeight;
		return (
			(viewport >= rect.top && viewport <= rect.bottom) ||
			(rect.top >= 0 && rect.top < viewport) ||
			(rect.bottom >= 0 && rect.bottom < viewport)
		);
	}

	const handleAnimateOnScroll = () => {
		const elsSlideUp = document.getElementsByClassName('animateSlideUp');
		for (let el of elsSlideUp) {
			if (isInViewport(el)) {
				el?.classList?.remove('slideUp');
				el?.classList?.add('slideUp');
			} else {
				el?.classList?.remove('slideUp');
			}
		}

		const elsWidthGrow = document.getElementsByClassName('animateWidthGrow');
		for (let el of elsWidthGrow) {
			if (isInViewport(el)) {
				el?.classList?.remove('widthGrow');
				el?.classList?.add('widthGrow');
			} else {
				el?.classList?.remove('widthGrow');
			}
		}
	};

	useEffect(() => {
		handleAnimateOnScroll();

		document.addEventListener('scroll', handleAnimateOnScroll);
		return () => {
			document.removeEventListener('scroll', handleAnimateOnScroll);
		};
	}, []);

	useEffect(() => {
		handleAnimateOnScroll();
	}, [router.pathname]);

	return (
		<MainLayout header={pageProps?.header} showContactUsSection={pageProps?.showContactUsSection}>
			<NextSeo
				canonical={router.asPath}
				title="Tekuton"
				description="We’re Igniting Innovation in the IoT Landscape"
			/>
			<Head>
				<meta
					name="viewport"
					// eslint-disable-next-line max-len
					content="width=device-width, minimum-scale=1, maximum-scale=3, initial-scale=1, shrink-to-fit=no, height=device-height, viewport-fit=cover"
				/>
			</Head>
			<Component />
		</MainLayout>
	);
};

export default AppRoot;
