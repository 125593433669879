import Footer from 'src/components/Footer';
import Header from 'src/components/Header';

import SectionContactUs from '../SectionContactUs';

const MainLayout = (props: any) => {
	const { children, header, showContactUsSection = true } = props;

	return (
		<div>
			<Header {...header} />
			<div>{children}</div>
			{showContactUsSection && <SectionContactUs />}
			<Footer />
		</div>
	);
};

export default MainLayout;
