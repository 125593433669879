import { useEffect, useState } from 'react';
import Link from 'next/link';
import classNames from 'classnames';

import Image from 'src/components/Image';

import Sidebar from '../Sidebar';

import classes from './index.module.less';

interface Props {
	className?: 'light' | 'dark';
	scrolledClassName?: 'light' | 'dark';
}

const Header = ({ className = 'light', scrolledClassName = 'dark' }: Props) => {
	const [scrolled, setScrolled] = useState<boolean>(false);
	const [showSidebar, setShowSidebar] = useState<boolean>(false);

	const handleScroll = () => {
		if (window.scrollY >= 86) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};

	useEffect(() => {
		document.addEventListener('scroll', handleScroll);

		return () => {
			document.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<>
			<nav
				className={classNames(classes.navbar, {
					[classes[className]]: !scrolled,
					[classNames(classes.scrolled, classes[scrolledClassName])]: scrolled,
				})}
			>
				<div className="container d-flex justify-content-between align-items-center">
					<Link className={classNames(classes.logo, classes['tekuton-logo'])} href="/">
						<Image
							alt="logo"
							// eslint-disable-next-line max-len
							src={`/images/logo${!scrolled ? (className === 'dark' ? '-dark' : '') : scrolledClassName === 'dark' ? '-dark' : ''}.svg`}
							height={32}
							width={146}
						/>
					</Link>
					<Image
						className={classes.menu}
						alt="menu"
						// eslint-disable-next-line max-len
						src={`/icons/icon-menu${!scrolled ? (className === 'dark' ? '-dark' : '') : scrolledClassName === 'dark' ? '-dark' : ''}.svg`}
						height={42}
						width={42}
						onClick={() => setShowSidebar(true)}
					/>
				</div>
			</nav>
			<Sidebar show={showSidebar} setShow={setShowSidebar} />
		</>
	);
};

export default Header;
