import classNames from 'classnames';

import classes from './index.module.less';

interface Props {
	className?: string;
	text?: string;
	disabled?: boolean;
}

const Button = ({ className = '', text = '', disabled = false }: Props) => {
	return (
		<button className={classNames(classes.button, className)} disabled={disabled}>
			{text}
		</button>
	);
};

export default Button;
